var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// ----------------------------------------------------------------------
export default function ConfirmDialog(_a) {
    var { title, content, action, open, onClose } = _a, other = __rest(_a, ["title", "content", "action", "open", "onClose"]);
    return (_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: open, onClose: onClose }, other, { children: [_jsx(DialogTitle, { sx: { pb: 2 }, children: title }), content && _jsxs(DialogContent, { sx: { typography: 'body2' }, children: [" ", content, " "] }), _jsxs(DialogActions, { children: [action, _jsx(Button, { variant: "outlined", color: "inherit", onClick: onClose, children: "Cancel" })] })] })));
}
