var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { useTheme, styled, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
//
import { LeftIcon, RightIcon } from './arrow-icons';
const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'filled' && prop !== 'hasChild' && prop !== 'shape',
})(({ filled, shape, hasChild, theme }) => (Object.assign(Object.assign(Object.assign(Object.assign({ color: 'inherit', transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shorter,
    }) }, (shape === 'rounded' && {
    borderRadius: theme.shape.borderRadius * 1.5,
})), (!filled && {
    opacity: 0.48,
    '&:hover': {
        opacity: 1,
    },
})), (filled && {
    color: alpha(theme.palette.common.white, 0.8),
    backgroundColor: alpha(theme.palette.grey[900], 0.48),
    '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[900],
    },
})), (hasChild && {
    zIndex: 9,
    top: '50%',
    position: 'absolute',
    marginTop: theme.spacing(-2.5),
}))));
export default function CarouselArrows(_a) {
    var { shape = 'circular', filled = false, icon, onNext, onPrev, children, leftButtonProps, rightButtonProps, sx } = _a, other = __rest(_a, ["shape", "filled", "icon", "onNext", "onPrev", "children", "leftButtonProps", "rightButtonProps", "sx"]);
    const theme = useTheme();
    const isRTL = theme.direction === 'rtl';
    const hasChild = !!children;
    if (hasChild) {
        return (_jsxs(Stack, Object.assign({ sx: sx }, other, { children: [onNext && (_jsx(StyledIconButton, Object.assign({ filled: filled, shape: shape, hasChild: !!children, onClick: onPrev }, leftButtonProps, { sx: Object.assign({ left: 16 }, leftButtonProps === null || leftButtonProps === void 0 ? void 0 : leftButtonProps.sx), children: _jsx(LeftIcon, { icon: icon, isRTL: isRTL }) }))), children, onPrev && (_jsx(StyledIconButton, Object.assign({ filled: filled, shape: shape, hasChild: !!children, onClick: onNext }, rightButtonProps, { sx: Object.assign({ right: 16 }, rightButtonProps === null || rightButtonProps === void 0 ? void 0 : rightButtonProps.sx), children: _jsx(RightIcon, { icon: icon, isRTL: isRTL }) })))] })));
    }
    return (_jsxs(Stack, Object.assign({ direction: "row", alignItems: "center", display: "inline-flex", sx: sx }, other, { children: [_jsx(StyledIconButton, Object.assign({ filled: filled, shape: shape, onClick: onPrev }, leftButtonProps, { children: _jsx(LeftIcon, { icon: icon, isRTL: isRTL }) })), _jsx(StyledIconButton, Object.assign({ filled: filled, shape: shape, onClick: onNext }, rightButtonProps, { children: _jsx(RightIcon, { icon: icon, isRTL: isRTL }) }))] })));
}
