var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, DialogActions, FormProvider, RHFTextField, Typography, placesToAddressMapper, useAsyncThrottle, } from '../../../index';
import { MapView } from '../map';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { geocodeByAddress } from 'react-places-autocomplete';
const AddressScema = Yup.object().shape({
    street: Yup.string().required('Street is required'),
    streetNumber: Yup.string().required('Street number is required'),
    city: Yup.string().required('City is required'),
    // area: Yup.string(),
    postalCode: Yup.string().required('Postal code is required'),
    fullAddress: Yup.string().required('fullAddress code is required'),
    googlePlaceId: Yup.string().required('googlePlaceId code is required'),
});
const GeoShcema = Yup.object().shape({
    lat: Yup.number().required('Lat is required'),
    lng: Yup.number().required('Long number is required'),
});
const NewAddressSchema = Yup.object().shape({
    address: AddressScema,
    geo: GeoShcema,
});
const ConfirmAddress = ({ selectedAddress, onSelectAddress, onClose, }) => {
    var _a, _b;
    const defaultValues = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return ({
            address: {
                street: ((_a = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address) === null || _a === void 0 ? void 0 : _a.street) || '',
                streetNumber: ((_b = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address) === null || _b === void 0 ? void 0 : _b.streetNumber) || '',
                city: ((_c = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address) === null || _c === void 0 ? void 0 : _c.city) || '',
                // area: selectedAddress?.address?.area || '',
                postalCode: ((_d = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address) === null || _d === void 0 ? void 0 : _d.postalCode) || '',
                fullAddress: ((_e = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address) === null || _e === void 0 ? void 0 : _e.fullAddress) || '',
                googlePlaceId: ((_f = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.address) === null || _f === void 0 ? void 0 : _f.googlePlaceId) || '',
            },
            geo: {
                lat: (_h = (_g = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.geo) === null || _g === void 0 ? void 0 : _g.lat) !== null && _h !== void 0 ? _h : 0,
                lng: (_k = (_j = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.geo) === null || _j === void 0 ? void 0 : _j.lng) !== null && _k !== void 0 ? _k : 0,
            },
        });
    }, [selectedAddress]);
    const [geo, setGeo] = useState(selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.geo);
    const markerLocation = {
        lat: (_a = geo === null || geo === void 0 ? void 0 : geo.lat) !== null && _a !== void 0 ? _a : 0,
        lng: (_b = geo === null || geo === void 0 ? void 0 : geo.lng) !== null && _b !== void 0 ? _b : 0,
    };
    const methods = useForm({
        resolver: yupResolver(NewAddressSchema),
        defaultValues,
    });
    const { reset, watch, handleSubmit } = methods;
    function formatAddress(googleAddress) {
        const { street, streetNumber, city, postalCode } = googleAddress.address;
        const formattedAddress = `${streetNumber} ${street}, ${city}, ${postalCode}`;
        return formattedAddress;
    }
    const throttledCallback = useAsyncThrottle((value) => __awaiter(void 0, void 0, void 0, function* () {
        const formattedAddress = formatAddress(value);
        try {
            const results = yield geocodeByAddress(formattedAddress);
            const addressData = yield placesToAddressMapper(results);
            if (addressData) {
                setGeo(addressData.geo);
            }
        }
        catch (error) {
            console.log('🚀 ~ file: confirm-address.tsx:96 ~ throttledCallback ~ error:', error);
        }
    }), 3000);
    useEffect(() => {
        const subscription = watch(throttledCallback);
        return () => subscription.unsubscribe();
    }, [watch, throttledCallback]);
    const onSubmit = handleSubmit((data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!data) {
                return;
            }
            const formattedAddress = formatAddress(data);
            data.address.fullAddress = formattedAddress;
            onSelectAddress(data);
            onClose === null || onClose === void 0 ? void 0 : onClose();
            reset();
        }
        catch (error) {
            console.error(error);
        }
    }));
    return (_jsxs(Box, { children: [_jsx(MapView, { center: markerLocation, markers: [markerLocation], style: {
                    height: '300px',
                    width: '100%',
                    borderRadius: 6,
                    overflow: 'hidden',
                } }), _jsxs(FormProvider, { methods: methods, onSubmit: onSubmit, children: [_jsxs(Box, { mt: 2, children: [_jsx(Typography, { variant: "h4", children: " \u0392\u03AC\u03BB\u03B5 \u03C4\u03B7\u03BD \u03B4\u03B9\u03B5\u03CD\u03B8\u03C5\u03BD\u03C3\u03B7 \u03C3\u03BF\u03C5 " }), _jsx(Typography, { variant: "body2", children: "\u0395\u03C0\u03B9\u03B2\u03B5\u03B2\u03B1\u03AF\u03C9\u03C3\u03B5 \u03CC\u03C4\u03B9 \u03B7 \u03B4\u03B9\u03B5\u03CD\u03B8\u03C5\u03BD\u03C3\u03AE \u03C3\u03BF\u03C5 \u03AD\u03C7\u03B5\u03B9 \u03C3\u03C5\u03BC\u03C0\u03BB\u03B7\u03C1\u03C9\u03B8\u03B5\u03AF \u03C3\u03C9\u03C3\u03C4\u03AC" }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', rowGap: 2, mt: 2 }, children: [_jsxs(Box, { sx: { display: 'flex', columnGap: 2 }, children: [_jsx(RHFTextField, { name: "address.street", placeholder: "\u039F\u03B4\u03CC\u03C2", InputLabelProps: { shrink: true } }), _jsx(RHFTextField, { name: "address.streetNumber", placeholder: "\u0391\u03C1\u03B9\u03B8\u03BC\u03CC\u03C2", InputLabelProps: { shrink: true } })] }), _jsx(RHFTextField, { name: "address.city", placeholder: "\u03A0\u03CC\u03BB\u03B7", InputLabelProps: { shrink: true } }), _jsx(RHFTextField, { name: "address.postalCode", placeholder: "\u03A4.\u039A.", InputLabelProps: { shrink: true } })] })] }), _jsxs(DialogActions, { sx: { p: 0, py: 2 }, children: [_jsx(Button, { color: "inherit", variant: "outlined", onClick: onClose, children: "Cancel" }), _jsx(Button, { variant: "contained", onClick: onSubmit, children: "Add" })] })] })] }));
};
export default ConfirmAddress;
