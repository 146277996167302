import { jsx as _jsx } from "react/jsx-runtime";
import GoogleMapReact from 'google-map-react';
import { LocationOnIcon } from '../../common-components/icons';
// ----------------------------------------------------------------------
const MapView = ({ center = { lat: 37.9838, lng: 23.7275 }, markers, style, }) => {
    return (_jsx("div", { style: style, children: _jsx(GoogleMapReact, { center: center, defaultZoom: 17, children: markers === null || markers === void 0 ? void 0 : markers.map((marker) => (_jsx(Marker, { lat: marker.lat, lng: marker.lng }, marker.lat))) }) }));
};
// eslint-disable-next-line
const Marker = ({ lat, lng }) => (_jsx(LocationOnIcon, { fontSize: "large", sx: { color: 'red' } }));
export default MapView;
