var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
export default function RHFAutocomplete(_a) {
    var { name, label, placeholder, helperText } = _a, other = __rest(_a, ["name", "label", "placeholder", "helperText"]);
    const { control, setValue } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(Autocomplete, Object.assign({}, field, { onChange: (event, newValue) => setValue(name, newValue, { shouldValidate: true }), renderInput: (params) => (_jsx(TextField, Object.assign({ label: label, placeholder: placeholder, error: !!error, helperText: error ? error === null || error === void 0 ? void 0 : error.message : helperText }, params))) }, other))) }));
}
