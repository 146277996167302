import { jsx as _jsx } from "react/jsx-runtime";
import FacebookLogin from 'react-facebook-login';
// import { useAuthContext } from 'src/auth/hooks';
const FacebookLoginButton = ({ onFailure, }) => {
    // const { loginWithFacebook } = useAuthContext();
    const onFacebookResponse = (userInfo) => {
        const { email, accessToken } = userInfo;
        if (email && accessToken) {
            // loginWithFacebook(email, accessToken);
        }
        else {
            onFailure('Error');
        }
    };
    return (_jsx(FacebookLogin, { appId: "1211044586516993", fields: "name,email,picture", scope: "public_profile, email", callback: onFacebookResponse }));
};
export default FacebookLoginButton;
