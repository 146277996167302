import { useLazyQuery } from '@apollo/client';
import { createContext, useCallback, useMemo, useReducer } from 'react';
import reducer, { StateTypes } from './state-reducer';
import { GET_ORGANIZATION } from 'src/services/Queries/organizationQueries';
import { IOrganization, IStore } from 'src/types';

export type StateType = {
  organization?: IOrganization;
  selectedStore?: IStore;
};

const initialState: StateType = {
  organization: undefined,
  selectedStore: undefined,
};

export type StateContextType = {
  organization?: IOrganization;
  selectedStore?: IStore;
  getOrganization: () => Promise<IOrganization | undefined>;
  setSelectedStore: (store: IStore) => void;
};

export const StateContext = createContext({} as StateContextType);

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [getOrganizationQuery] = useLazyQuery(GET_ORGANIZATION);
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSelectedStore = useCallback((store: IStore) => {
    if (!store) {
      return;
    }
    localStorage.setItem('storeId', store.id);
    dispatch({
      type: StateTypes.SET_SELECTED_STORE,
      payload: { store: store },
    });
  }, []);

  const getOrganization = useCallback(async () => {
    const organizationResponse = await getOrganizationQuery();
    // const organizationResponse = {
    //   data: {
    //     organization: MockOrganization,
    //   },
    // };

    const organization: IOrganization = organizationResponse.data.organization;
    dispatch({
      type: StateTypes.GET_ORGANIZATION,
      payload: {
        organization,
      },
    });
    localStorage.setItem('organizationId', organization?.id);
    const savedStoreId = localStorage.getItem('storeId');
    const stores = organization?.stores?.map((orgStore) => orgStore.store);
    const storeToSelect =
      stores?.find((store: IStore) => store?.id === savedStoreId) ??
      stores?.[0];

    setSelectedStore(storeToSelect);

    return organization;
  }, [setSelectedStore, getOrganizationQuery]);

  const memoizedValue = useMemo(
    () => ({
      organization: state.organization,
      selectedStore: state.selectedStore,
      // Functions
      getOrganization,
      setSelectedStore,
    }),
    [state, setSelectedStore, getOrganization],
  );

  return (
    <StateContext.Provider value={memoizedValue}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
