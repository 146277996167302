var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
// routes
import { RouterLink } from '@shopping-spree/web-utils';
//
import { Iconify } from '../../iconify';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
export default function NavItem(_a) {
    var { item, open, depth, active, config, externalLink } = _a, other = __rest(_a, ["item", "open", "depth", "active", "config", "externalLink"]);
    const { title, path, icon, info, children, disabled, caption, roles } = item;
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledItem, Object.assign({ disableGutters: true, disabled: disabled, active: active, depth: depth, config: config }, other, { children: [_jsxs(_Fragment, { children: [icon && _jsx(StyledIcon, { size: config.iconSize, children: icon }), subItem && (_jsx(StyledIcon, { size: config.iconSize, children: _jsx(StyledDotIcon, { active: active }) }))] }), !(config.hiddenLabel && !subItem) && (_jsx(ListItemText, { primary: title, secondary: caption ? (_jsx(Tooltip, { title: caption, placement: "top-start", children: _jsx("span", { children: caption }) })) : null, primaryTypographyProps: {
                    noWrap: true,
                    typography: 'body2',
                    textTransform: 'capitalize',
                    fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
                }, secondaryTypographyProps: {
                    noWrap: true,
                    component: 'span',
                    typography: 'caption',
                    color: 'text.disabled',
                } })), info && (_jsx(Box, { component: "span", sx: { ml: 1, lineHeight: 0 }, children: info })), !!children && (_jsx(Iconify, { width: 16, icon: open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill', sx: { ml: 1, flexShrink: 0 } }))] })));
    // Hidden item by role
    if (roles && !roles.includes(`${config.currentRole}`)) {
        return null;
    }
    // External link
    if (externalLink)
        return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", underline: "none", color: "inherit", sx: Object.assign({}, (disabled && {
                cursor: 'default',
            })), children: renderContent }));
    // Has child
    if (children) {
        return renderContent;
    }
    // Default
    return (_jsx(Link, { component: RouterLink, href: path, underline: "none", color: "inherit", sx: Object.assign({}, (disabled && {
            cursor: 'default',
        })), children: renderContent }));
}
