var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
//
import { Iconify } from '../iconify';
import { Image } from '../image';
import RejectionFiles from './errors-rejection-files';
// ----------------------------------------------------------------------
export default function UploadAvatar(_a) {
    var { error, file, disabled, helperText, sx } = _a, other = __rest(_a, ["error", "file", "disabled", "helperText", "sx"]);
    const { getRootProps, isDragActive, isDragReject, fileRejections } = useDropzone(Object.assign({ multiple: false, disabled, accept: {
            'image/*': [],
        } }, other));
    const hasFile = !!file;
    const hasError = isDragReject || !!error;
    const imgUrl = typeof file === 'string' ? file : file === null || file === void 0 ? void 0 : file.preview;
    const renderPreview = hasFile && (_jsx(Image, { alt: "avatar", src: imgUrl, sx: {
            width: 1,
            height: 1,
            borderRadius: '50%',
        } }));
    const renderPlaceholder = (_jsxs(Stack, { alignItems: "center", justifyContent: "center", spacing: 1, className: "upload-placeholder", sx: Object.assign(Object.assign({ top: 0, left: 0, width: 1, height: 1, zIndex: 9, borderRadius: '50%', position: 'absolute', color: 'text.disabled', bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08), transition: (theme) => theme.transitions.create(['opacity'], {
                duration: theme.transitions.duration.shorter,
            }), '&:hover': {
                opacity: 0.72,
            } }, (hasError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
        })), (hasFile && {
            zIndex: 9,
            opacity: 0,
            color: 'common.white',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64),
        })), children: [_jsx(Iconify, { icon: "solar:camera-add-bold", width: 32 }), _jsx(Typography, { variant: "caption", children: file ? 'Update photo' : 'Upload photo' })] }));
    const renderContent = (_jsxs(Box, { sx: {
            width: 1,
            height: 1,
            overflow: 'hidden',
            borderRadius: '50%',
            position: 'relative',
        }, children: [renderPreview, renderPlaceholder] }));
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({}, getRootProps(), { sx: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ p: 1, m: 'auto', width: 144, height: 144, cursor: 'pointer', overflow: 'hidden', borderRadius: '50%', border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}` }, (isDragActive && {
                    opacity: 0.72,
                })), (disabled && {
                    opacity: 0.48,
                    pointerEvents: 'none',
                })), (hasError && {
                    borderColor: 'error.light',
                })), (hasFile && Object.assign(Object.assign({}, (hasError && {
                    bgcolor: 'error.lighter',
                })), { '&:hover .upload-placeholder': {
                        opacity: 1,
                    } }))), sx), children: renderContent })), helperText && helperText, _jsx(RejectionFiles, { fileRejections: fileRejections })] }));
}
