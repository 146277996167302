import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const httpLink = createHttpLink({
  uri: 'https://gql-business.dev.spree.gr/',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  const organizationId = localStorage.getItem('organizationId');
  const storeId = localStorage.getItem('storeId');

  const updatedHeaders: Record<string, string> = {
    authorization: token ? `Bearer ${token}` : '',
  };

  if (organizationId) {
    updatedHeaders['organization-context-id'] = organizationId;
  }

  if (storeId) {
    updatedHeaders['store-context-id'] = storeId;
  }

  return {
    headers: {
      ...headers,
      ...updatedHeaders,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
