import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { SplashScreen } from '@shopping-spree/web-ui';

// ----------------------------------------------------------------------

// ONBOARDING
const RootPage = lazy(() => import('src/pages/on-boarding/on-boarding'));

// ----------------------------------------------------------------------

export const onBoardingRoutes = [
  {
    path: 'onBoarding',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ element: <RootPage />, index: true }],
  },
];
