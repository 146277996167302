// ----------------------------------------------------------------------

export const PRODUCT_GENDER_OPTIONS = [
  { label: 'Men', value: 'Men' },
  { label: 'Women', value: 'Women' },
  { label: 'Kids', value: 'Kids' },
];

export const PRODUCT_CATEGORY_OPTIONS = ['Shose', 'Apparel', 'Accessories'];

export const PRODUCT_RATING_OPTIONS = [
  'up4Star',
  'up3Star',
  'up2Star',
  'up1Star',
];

export const PRODUCT_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

export const PRODUCT_COLOR_NAME_OPTIONS = [
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'cyan', label: 'Cyan' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'violet', label: 'Violet' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' },
];

export const PRODUCT_SIZE_OPTIONS = [
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '8.5', label: '8.5' },
  { value: '9', label: '9' },
  { value: '9.5', label: '9.5' },
  { value: '10', label: '10' },
  { value: '10.5', label: '10.5' },
  { value: '11', label: '11' },
  { value: '11.5', label: '11.5' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
];

export const PRODUCT_STOCK_OPTIONS = [
  { value: 'in stock', label: 'In stock' },
  { value: 'low stock', label: 'Low stock' },
  { value: 'out of stock', label: 'Out of stock' },
];

export const PRODUCT_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High - Low' },
  { value: 'priceAsc', label: 'Price: Low - High' },
];

export const PRODUCT_CATEGORY_GROUP_OPTIONS = [
  {
    group: 'Clothing',
    classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather', 'Accessories'],
  },
  {
    group: 'Tailored',
    classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats', 'Apparel'],
  },
  {
    group: 'Accessories',
    classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'],
  },
];

export const PRODUCT_CHECKOUT_STEPS = ['Cart', 'Billing & address', 'Payment'];

export const PRODUCT_CATEGORIES = [
  { value: 'featured', code: 'Featured' },
  { value: 'newest', code: 'Newest' },
  { value: 'priceDesc', code: 'Price: High - Low' },
  { value: 'priceAsc', code: 'Price: Low - High' },
  { value: 'bestSellers', code: 'Best Sellers' },
  { value: 'mostPopular', code: 'Most Popular' },
  { value: 'topRated', code: 'Top Rated' },
  { value: 'onSale', code: 'On Sale' },
  { value: 'clearanceItems', code: 'Clearance Items' },
  { value: 'limitedEdition', code: 'Limited Edition' },
];

export const STORE_AMENITIES = [
  {
    code: 'wifi',
    value: 'WIFI',
  },
  {
    code: 'pos',
    value: 'POS',
  },
  {
    code: 'parcking',
    value: 'Parcking',
  },
];

export const MOCK_MEMBERS = [
  {
    _id: '1',
    name: 'Teo Gomes',
    email: 'tptls@hotmail.com',
    storeIds: ['ZARA', 'Pull and Bear'],
    role: 'Editor',
    avatarURL:
      'https://media.licdn.com/dms/image/D4D03AQH_REGnWLMAmw/profile-displayphoto-shrink_800_800/0/1671406160604?e=2147483647&v=beta&t=RZFhGB3KJ7UvUImoaKh9KnlbFluWAVGMCGNK5dPPhJw',
  },
];

export const MOCKPRODUCTS = [
  {
    id: '1',
    sku: 'SKU001',
    name: 'Product 1',
    code: 'PROD001',
    price: 10.99,
    taxes: 1.5,
    tags: ['tag1', 'tag2'],
    gender: 'male',
    sizes: ['S', 'M', 'L'],
    publish: '2023-07-16',
    coverUrl: 'https://example.com/product1-cover.jpg',
    images: [
      'https://example.com/product1-image1.jpg',
      'https://example.com/product1-image2.jpg',
    ],
    colors: ['red', 'blue', 'green'],
    quantity: 10,
    category: 'clothing',
    available: 5,
    totalSold: 100,
    description: 'Product 1 description',
    totalRatings: 4.5,
    totalReviews: 50,
    inventoryType: 'standard',
    subDescription: 'Product 1 sub-description',
    priceSale: null,
    reviews: [],
    // reviews: [
    //   { id: '1', productId: '1', rating: 4.5, comment: 'Great product!' },
    //   { id: '2', productId: '1', rating: 3.5, comment: 'Average product.' },
    // ],
    createdAt: new Date(),
    ratings: [
      { name: 'John', starCount: 5, reviewCount: 10 },
      { name: 'Jane', starCount: 4, reviewCount: 20 },
    ],
    saleLabel: { enabled: false, content: '' },
    newLabel: { enabled: true, content: 'New' },
  },
  // Add more products here...
];

export const MOCKSTORES = [
  {
    id: '1',
    sku: 'SKU001',
    name: 'Fashion Emporium',
    address: 'Ermou 2, Athens',
    code: 'FE001',
    price: 49.99,
    taxes: 4.99,
    tags: ['fashion', 'clothing', 'accessories'],
    gender: 'unisex',
    sizes: ['S', 'M', 'L', 'XL'],
    publish: 'draft',
    coverUrl: 'https://picsum.photos/500',
    images: [
      'https://example.com/image1.jpg',
      'https://example.com/image2.jpg',
      'https://example.com/image3.jpg',
    ],
    colors: ['black', 'white', 'blue'],
    quantity: 100,
    category: 'Apparel',
    available: 50,
    totalSold: 50,
    description:
      'Fashion Emporium - Your one-stop shop for trendy clothing and accessories.',
    totalRatings: 25,
    totalReviews: 10,
    inventoryType: 'regular',
    subDescription: 'Get 10% off on all orders above $100!',
    priceSale: null,
    createdAt: new Date('2023-07-01'),
    availability: [
      {
        day: 'Sunday',
        isOpen: false,
      },
      {
        day: 'Monday',
        isOpen: true,
        fromTime: new Date('2023-07-13T06:00:00.000Z'),
        toTime: new Date('2023-07-13T14:00:00.000Z'),
      },
      {
        day: 'Tuesday',
        isOpen: false,
        fromTime: new Date('2023-07-13T06:00:00.000Z'),
        toTime: new Date('2023-07-13T14:00:00.000Z'),
      },
      {
        day: 'Wednesday',
        isOpen: true,
        fromTime: new Date('2023-07-13T08:00:00.000Z'),
        toTime: new Date('2023-07-13T14:00:00.000Z'),
      },
      {
        day: 'Thursday',
        isOpen: true,
        fromTime: new Date('2023-07-13T06:00:00.000Z'),
        toTime: new Date('2023-07-13T14:00:00.000Z'),
      },
      {
        day: 'Friday',
        isOpen: true,
        fromTime: new Date('2023-07-13T06:00:00.000Z'),
        toTime: new Date('2023-07-13T14:00:00.000Z'),
      },
      {
        day: 'Saturday',
        isOpen: true,
        fromTime: new Date('2023-07-13T06:00:00.000Z'),
        toTime: new Date('2023-07-13T14:00:00.000Z'),
      },
    ],
  },
  {
    id: 'store2',
    sku: 'XYZ789',
    name: 'Tech Hub',
    code: 'TH002',
    address: 'Kentro 210, Athens',
    price: 199.99,
    taxes: 10.0,
    tags: ['electronics', 'gadgets', 'technology'],
    gender: 'unisex',
    sizes: [],
    publish: 'published',
    coverUrl: 'https://picsum.photos/200',
    images: [
      'https://example.com/store2/image1.jpg',
      'https://example.com/store2/image2.jpg',
    ],
    colors: ['silver', 'black'],
    quantity: 50,
    category: 'smartphones',
    available: 50,
    totalSold: 0,
    description: 'The latest smartphone with advanced features.',
    totalRatings: 0,
    totalReviews: 0,
    inventoryType: 'physical',
    subDescription: 'Available in silver and black colors.',
    priceSale: 179.99,
    createdAt: new Date(),
  },
  {
    id: 'store3',
    sku: 'DEF456',
    name: 'Sports Zone',
    address: 'Omonoia 10, Athens',
    code: 'SZ003',
    price: 49.99,
    taxes: 3.5,
    tags: ['sports', 'fitness', 'men', 'women'],
    gender: 'unisex',
    sizes: ['S', 'M', 'L'],
    publish: 'published',
    coverUrl: 'https://picsum.photos/300',
    images: [
      'https://example.com/store3/image1.jpg',
      'https://example.com/store3/image2.jpg',
      'https://example.com/store3/image3.jpg',
      'https://example.com/store3/image4.jpg',
    ],
    colors: ['blue', 'black', 'white'],
    quantity: 200,
    category: 'sportswear',
    available: 150,
    totalSold: 50,
    description: 'High-quality sportswear for active individuals.',
    totalRatings: 30,
    totalReviews: 15,
    inventoryType: 'physical',
    subDescription: 'Available in multiple sizes and colors.',
    priceSale: null,
    createdAt: new Date(),
  },
];
