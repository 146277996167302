import { alpha } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';
// ----------------------------------------------------------------------
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
// ----------------------------------------------------------------------
export default function Button(theme) {
    const isLight = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const inheritColor = ownerState.color === 'inherit';
        const containedVariant = ownerState.variant === 'contained';
        const outlinedVariant = ownerState.variant === 'outlined';
        const textVariant = ownerState.variant === 'text';
        const softVariant = ownerState.variant === 'soft';
        const smallSize = ownerState.size === 'small';
        const mediumSize = ownerState.size === 'medium';
        const largeSize = ownerState.size === 'large';
        const defaultStyle = Object.assign(Object.assign({}, (inheritColor && Object.assign(Object.assign(Object.assign(Object.assign({}, (containedVariant && {
            color: isLight ? theme.palette.common.white : theme.palette.grey[800],
            backgroundColor: isLight ? theme.palette.grey[800] : theme.palette.common.white,
            '&:hover': {
                backgroundColor: isLight ? theme.palette.grey[700] : theme.palette.grey[400],
            },
        })), (outlinedVariant && {
            borderColor: alpha(theme.palette.grey[500], 0.32),
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        })), (textVariant && {
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        })), (softVariant && {
            color: theme.palette.text.primary,
            backgroundColor: alpha(theme.palette.grey[500], 0.08),
            '&:hover': {
                backgroundColor: alpha(theme.palette.grey[500], 0.24),
            },
        })))), (outlinedVariant && {
            '&:hover': {
                borderColor: 'currentColor',
                boxShadow: '0 0 0 0.5px currentColor',
            },
        }));
        const colorStyle = COLORS.map((color) => (Object.assign({}, (ownerState.color === color && Object.assign(Object.assign({}, (containedVariant && {
            '&:hover': {
                boxShadow: theme.customShadows[color],
            },
        })), (softVariant && {
            color: theme.palette[color][isLight ? 'dark' : 'light'],
            backgroundColor: alpha(theme.palette[color].main, 0.16),
            '&:hover': {
                backgroundColor: alpha(theme.palette[color].main, 0.32),
            },
        }))))));
        const disabledState = {
            [`&.${buttonClasses.disabled}`]: Object.assign({}, (softVariant && {
                backgroundColor: theme.palette.action.disabledBackground,
            })),
        };
        const size = Object.assign(Object.assign(Object.assign({}, (smallSize && Object.assign({ height: 30, fontSize: 13, paddingLeft: 8, paddingRight: 8 }, (textVariant && {
            paddingLeft: 4,
            paddingRight: 4,
        })))), (mediumSize && Object.assign({ paddingLeft: 12, paddingRight: 12 }, (textVariant && {
            paddingLeft: 8,
            paddingRight: 8,
        })))), (largeSize && Object.assign({ height: 48, fontSize: 15, paddingLeft: 16, paddingRight: 16 }, (textVariant && {
            paddingLeft: 10,
            paddingRight: 10,
        }))));
        return [defaultStyle, ...colorStyle, disabledState, size];
    };
    return {
        MuiButton: {
            defaultProps: {
                color: 'inherit',
                disableElevation: true,
            },
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
