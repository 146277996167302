import { alpha } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';
// ----------------------------------------------------------------------
const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
// ----------------------------------------------------------------------
export default function Progress(theme) {
    const rootStyles = (ownerState) => {
        const bufferVariant = ownerState.variant === 'buffer';
        const defaultStyle = Object.assign({ borderRadius: 4, [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 4,
            } }, (bufferVariant && {
            backgroundColor: 'transparent',
        }));
        const colorStyle = COLORS.map((color) => (Object.assign({}, (ownerState.color === color && {
            backgroundColor: alpha(theme.palette[color].main, 0.24),
        }))));
        return [defaultStyle, ...colorStyle];
    };
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyles(ownerState),
            },
        },
    };
}
