import { gql } from '@apollo/client';

export const GET_MY_USER = gql(`
query Me {
    me {
      id
      profile {
        name
        gender
        dateOfBirth
        bio
      }
    }
  }
`);

export const SIGN_UP = gql`
  mutation SignUp($signUpUser: UserInput!, $signUpProvider: AUTH_PROVIDER!) {
    signUp(user: $signUpUser, provider: $signUpProvider) {
      jwt
      user {
        id
      }
    }
  }
`;

export const AUTHENTICATE = gql`
  mutation Authenticate(
    $provider: AUTH_PROVIDER!
    $credentials: CredentialsInput!
  ) {
    authenticate(provider: $provider, credentials: $credentials) {
      jwt
      user {
        id
      }
    }
  }
`;
