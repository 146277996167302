var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
// @mui
import Box from '@mui/material/Box';
// ----------------------------------------------------------------------
function AvatarShape(_a) {
    var { sx } = _a, other = __rest(_a, ["sx"]);
    return (_jsx(Box, Object.assign({ component: "svg", width: 144, height: 62, fill: "none", viewBox: "0 0 144 62", xmlns: "http://www.w3.org/2000/svg", sx: Object.assign({ color: 'background.paper' }, sx) }, other, { children: _jsx("path", { d: "m111.34 23.88c-10.62-10.46-18.5-23.88-38.74-23.88h-1.2c-20.24 0-28.12 13.42-38.74 23.88-7.72 9.64-19.44 11.74-32.66 12.12v26h144v-26c-13.22-.38-24.94-2.48-32.66-12.12z", fill: "currentColor", fillRule: "evenodd" }) })));
}
export default memo(AvatarShape);
