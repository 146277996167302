import { jsx as _jsx } from "react/jsx-runtime";
// @mui
import Box from '@mui/material/Box';
//
import { Image } from '../image';
export default function SingleFilePreview({ imgUrl = '' }) {
    return (_jsx(Box, { sx: {
            p: 1,
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            position: 'absolute',
        }, children: _jsx(Image, { alt: "file preview", src: imgUrl, sx: {
                width: 1,
                height: 1,
                borderRadius: 1,
            } }) }));
}
