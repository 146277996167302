// routes
import Router from 'src/routes/sections';
// theme
import { SnackbarProvider, ThemeProvider } from '@shopping-spree/web-ui';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import {
  ProgressBar,
  MotionLazy,
  SettingsDrawer,
  SettingsProvider,
  LocalizationProvider,
  AdapterDayjs,
} from '@shopping-spree/web-ui';
// auth
import { GoogleOAuthProvider } from '@shopping-spree/web-ui';
import { AuthProvider, AuthConsumer } from 'src/context/auth/context/jwt';
import StateProvider from './context/state/state-provider';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <StateProvider>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'bold', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SettingsDrawer />
                <ProgressBar />
                <AuthConsumer>
                  <GoogleOAuthProvider clientId="563215136088-r4l06ept1mc2ap325jkp459nb8qqdfqs.apps.googleusercontent.com">
                    <SnackbarProvider>
                      <Router />
                    </SnackbarProvider>
                  </GoogleOAuthProvider>
                </AuthConsumer>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </StateProvider>
  );
}
