var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
export default function EmptyContent(_a) {
    var { title, imgUrl, action, filled, description, sx } = _a, other = __rest(_a, ["title", "imgUrl", "action", "filled", "description", "sx"]);
    return (_jsxs(Stack, Object.assign({ flexGrow: 1, alignItems: "center", justifyContent: "center", sx: Object.assign(Object.assign({ px: 3, height: 1 }, (filled && {
            borderRadius: 2,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
            border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.08)}`,
        })), sx) }, other, { children: [_jsx(Box, { component: "img", alt: "empty content", src: imgUrl || '/assets/icons/empty/ic_content.svg', sx: { width: 1, maxWidth: 160 } }), title && (_jsx(Typography, { variant: "h6", component: "span", sx: { mt: 1, color: 'text.disabled', textAlign: 'center' }, children: title })), description && (_jsx(Typography, { variant: "caption", sx: { mt: 1, color: 'text.disabled', textAlign: 'center' }, children: description })), action && action] })));
}
