var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// assets
import { UploadIllustration } from '../../assets/illustrations';
//
import { Iconify } from '../iconify';
import RejectionFiles from './errors-rejection-files';
import MultiFilePreview from './preview-multi-file';
import SingleFilePreview from './preview-single-file';
// ----------------------------------------------------------------------
export default function Upload(_a) {
    var { theme, disabled, multiple = false, error, helperText, 
    //
    file, onDelete, 
    //
    files, thumbnail, onUpload, onRemove, onRemoveAll, sx } = _a, other = __rest(_a, ["theme", "disabled", "multiple", "error", "helperText", "file", "onDelete", "files", "thumbnail", "onUpload", "onRemove", "onRemoveAll", "sx"]);
    const { getRootProps, isDragActive, isDragReject, fileRejections } = useDropzone(Object.assign({ multiple,
        disabled }, other));
    const hasFile = !!file && !multiple;
    const hasFiles = !!files && multiple && !!files.length;
    const hasError = isDragReject || !!error;
    const renderPlaceholder = (_jsxs(Stack, { spacing: 3, alignItems: "center", justifyContent: "center", flexWrap: "wrap", children: [_jsx(UploadIllustration, { sx: { width: 1, maxWidth: 200 }, theme: theme }), _jsxs(Stack, { spacing: 1, sx: { textAlign: 'center' }, children: [_jsx(Typography, { variant: "h6", children: "Drop or Select file" }), _jsxs(Typography, { variant: "body2", sx: { color: 'text.secondary' }, children: ["Drop files here or click", _jsx(Box, { component: "span", sx: {
                                    mx: 0.5,
                                    color: 'primary.main',
                                    textDecoration: 'underline',
                                }, children: "browse" }), "thorough your machine"] })] })] }));
    const renderSinglePreview = (_jsx(SingleFilePreview, { imgUrl: typeof file === 'string' ? file : file === null || file === void 0 ? void 0 : file.preview }));
    const removeSinglePreview = hasFile && onDelete && (_jsx(IconButton, { size: "small", onClick: onDelete, sx: {
            top: 16,
            right: 16,
            zIndex: 9,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.common.white, 0.8),
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
        }, children: _jsx(Iconify, { icon: "mingcute:close-line", width: 18 }) }));
    const renderMultiPreview = hasFiles && (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { my: 3 }, children: _jsx(MultiFilePreview, { files: files, thumbnail: thumbnail, onRemove: onRemove, theme: theme }) }), _jsxs(Stack, { direction: "row", justifyContent: "flex-end", spacing: 1.5, children: [onRemoveAll && (_jsx(Button, { color: "inherit", variant: "outlined", size: "small", onClick: onRemoveAll, children: "Remove All" })), onUpload && (_jsx(Button, { size: "small", variant: "contained", onClick: onUpload, startIcon: _jsx(Iconify, { icon: "eva:cloud-upload-fill" }), children: "Upload" }))] })] }));
    return (_jsxs(Box, { sx: Object.assign({ width: 1, position: 'relative' }, sx), children: [_jsx(Box, Object.assign({}, getRootProps(), { sx: Object.assign(Object.assign(Object.assign(Object.assign({ p: 5, outline: 'none', borderRadius: 1, cursor: 'pointer', overflow: 'hidden', position: 'relative', bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08), border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`, transition: (theme) => theme.transitions.create(['opacity', 'padding']), '&:hover': {
                        opacity: 0.72,
                    } }, (isDragActive && {
                    opacity: 0.72,
                })), (disabled && {
                    opacity: 0.48,
                    pointerEvents: 'none',
                })), (hasError && {
                    color: 'error.main',
                    bgcolor: 'error.lighter',
                    borderColor: 'error.light',
                })), (hasFile && {
                    padding: '24% 0',
                })), children: hasFile ? renderSinglePreview : renderPlaceholder })), removeSinglePreview, helperText && helperText, _jsx(RejectionFiles, { fileRejections: fileRejections }), renderMultiPreview] }));
}
