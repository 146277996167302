import { jsx as _jsx } from "react/jsx-runtime";
// import { useAuthContext } from 'src/auth/hooks';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '../../../common-components/button';
const GoogleButton = () => {
    // const { loginWithGoogle } = useAuthContext();
    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            const { access_token } = tokenResponse;
            if (access_token) {
                // loginWithGoogle(access_token);
            }
        },
    });
    return (_jsx(Button, { variant: "contained", onClick: () => login(), children: "Sign in with Google" }));
};
export default GoogleButton;
