var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
export default function SearchNotFound(_a) {
    var { query, sx } = _a, other = __rest(_a, ["query", "sx"]);
    return query ? (_jsxs(Paper, Object.assign({ sx: Object.assign({ bgcolor: 'unset', textAlign: 'center' }, sx) }, other, { children: [_jsx(Typography, { variant: "h6", gutterBottom: true, children: "Not Found" }), _jsxs(Typography, { variant: "body2", children: ["No results found for \u00A0", _jsxs("strong", { children: ["\"", query, "\""] }), ".", _jsx("br", {}), " Try checking for typos or using complete words."] })] }))) : (_jsx(Typography, { variant: "body2", sx: sx, children: "Please enter keywords" }));
}
