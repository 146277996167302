import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
  query GetOrganization {
    organization {
      id
      stores {
        status
        store {
          id
          address {
            city
            fullAddress
            googlePlaceId
            postalCode
            street
            streetNumber
          }
        }
      }
    }
  }
`;

export const CREATE_STORE = gql`
  mutation CreateStore($storeInput: StoreInput!) {
    createStore(storeInput: $storeInput) {
      id
    }
  }
`;
