import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Grid, Stack } from '../../../index';
import { SubscriptionPlan } from './subscription-plan';
const SubscriptionPlans = ({ plans, selectedPlanId, onSelectPlan, }) => {
    const onPlanSelect = useCallback((plan) => {
        onSelectPlan === null || onSelectPlan === void 0 ? void 0 : onSelectPlan(plan);
    }, []);
    return (_jsx(Stack, { spacing: 3, sx: { p: 3 }, children: _jsx(Grid, { container: true, spacing: 3, children: plans === null || plans === void 0 ? void 0 : plans.map((plan) => (_jsx(SubscriptionPlan, { plan: plan, selectedPlanId: selectedPlanId, onSelect: onPlanSelect }, plan.id))) }) }));
};
export default SubscriptionPlans;
