var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// ----------------------------------------------------------------------
export default function TableSkeleton(_a) {
    var other = __rest(_a, []);
    return (_jsx(TableRow, Object.assign({}, other, { children: _jsx(TableCell, { colSpan: 12, children: _jsxs(Stack, { spacing: 3, direction: "row", alignItems: "center", children: [_jsx(Skeleton, { sx: { borderRadius: 1.5, width: 48, height: 48, flexShrink: 0 } }), _jsx(Skeleton, { sx: { width: 1, height: 12 } }), _jsx(Skeleton, { sx: { width: 180, height: 12 } }), _jsx(Skeleton, { sx: { width: 160, height: 12 } }), _jsx(Skeleton, { sx: { width: 140, height: 12 } }), _jsx(Skeleton, { sx: { width: 120, height: 12 } })] }) }) })));
}
