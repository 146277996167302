import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
// rtl
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
// emotion
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
export default function RTL({ children, themeDirection }) {
    useEffect(() => {
        document.dir = themeDirection;
    }, [themeDirection]);
    const cacheRtl = createCache({
        key: 'rtl',
        prepend: true,
        // eslint-disable-next-line
        // @ts-ignore
        stylisPlugins: [prefixer, rtlPlugin],
    });
    if (themeDirection === 'rtl') {
        return _jsx(CacheProvider, { value: cacheRtl, children: children });
    }
    return _jsx(_Fragment, { children: children });
}
// ----------------------------------------------------------------------
export function direction(themeDirection) {
    const theme = {
        direction: themeDirection,
    };
    return theme;
}
