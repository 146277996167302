var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useCallback, useRef } from 'react';
// eslint-disable-next-line
const useAsyncThrottle = (callback, delay) => {
    const timeoutRef = useRef(undefined);
    const throttledCallback = useCallback((...args) => __awaiter(void 0, void 0, void 0, function* () {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
            yield callback(...args);
            timeoutRef.current = undefined;
        }), delay);
    }), [callback, delay]);
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = undefined;
            }
        };
    }, []);
    return throttledCallback;
};
export default useAsyncThrottle;
