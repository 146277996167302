import { StateType } from './state-provider';
import { ActionMapType } from '../auth/types';
import { IOrganization, IStore } from 'src/types';

export enum StateTypes {
  GET_ORGANIZATION = 'GET_ORGANIZATION',
  SET_SELECTED_STORE = 'SET_SELECTED_STORE',
}

type Payload = {
  [StateTypes.GET_ORGANIZATION]: {
    organization: IOrganization;
  };
  [StateTypes.SET_SELECTED_STORE]: {
    store: IStore;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: StateType, actions: ActionsType) => {
  if (actions.type === StateTypes.GET_ORGANIZATION) {
    return {
      ...state,
      organization: actions.payload.organization,
    };
  }
  if (actions.type === StateTypes.SET_SELECTED_STORE) {
    return {
      ...state,
      selectedStore: actions.payload.store,
    };
  }

  return state;
};

export default reducer;
