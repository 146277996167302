import { jsx as _jsx } from "react/jsx-runtime";
//
import { Iconify } from '../iconify';
export function LeftIcon({ icon = 'eva:arrow-ios-forward-fill', isRTL, }) {
    return (_jsx(Iconify, { icon: icon, sx: Object.assign({ transform: ' scaleX(-1)' }, (isRTL && {
            transform: ' scaleX(1)',
        })) }));
}
export function RightIcon({ icon = 'eva:arrow-ios-forward-fill', isRTL, }) {
    return (_jsx(Iconify, { icon: icon, sx: Object.assign({}, (isRTL && {
            transform: ' scaleX(-1)',
        })) }));
}
