var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel, { formControlLabelClasses, } from '@mui/material/FormControlLabel';
export function RHFCheckbox(_a) {
    var { name, helperText } = _a, other = __rest(_a, ["name", "helperText"]);
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs("div", { children: [_jsx(FormControlLabel, Object.assign({ control: _jsx(Checkbox, Object.assign({}, field, { checked: field.value })) }, other)), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))] })) }));
}
export function RHFMultiCheckbox(_a) {
    var { row, name, label, options, spacing, helperText, sx } = _a, other = __rest(_a, ["row", "name", "label", "options", "spacing", "helperText", "sx"]);
    const { control } = useFormContext();
    const getSelected = (selectedItems, item) => selectedItems.includes(item)
        ? selectedItems.filter((value) => value !== item)
        : [...selectedItems, item];
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { component: "fieldset", children: [label && (_jsx(FormLabel, { component: "legend", sx: { typography: 'body2' }, children: label })), _jsx(FormGroup, { sx: Object.assign(Object.assign(Object.assign({}, (row && {
                        flexDirection: 'row',
                    })), { [`& .${formControlLabelClasses.root}`]: Object.assign({ '&:not(:last-of-type)': {
                                mb: spacing || 0,
                            } }, (row && {
                            mr: 0,
                            '&:not(:last-of-type)': {
                                mr: spacing || 2,
                            },
                        })) }), sx), children: options.map((option) => (_jsx(FormControlLabel, Object.assign({ control: _jsx(Checkbox, { checked: field.value.includes(option.value), onChange: () => field.onChange(getSelected(field.value, option.value)) }), label: option.label }, other), option.value))) }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { mx: 0 }, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))] })) }));
}
