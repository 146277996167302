import { useCallback, useEffect, useState } from 'react';
import { useAppState } from 'src/context/state/use-state-context';
// routes
import { useRouter } from '@shopping-spree/web-utils';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const OnBoardingGuard = ({ children }: { children: React.ReactNode }) => {
  const { organization } = useAppState();
  const [checked, setChecked] = useState(false);
  const router = useRouter();

  const check = useCallback(async () => {
    const hasStores = (organization?.stores?.length ?? 0) > 0;
    setChecked(hasStores);
    if (!hasStores) {
      router.replace(paths.onBoarding.root);
    }
  }, [organization, router]);

  useEffect(() => {
    check();
  }, [check, organization]);

  if (!checked) {
    return null;
  }
  return <>{children}</>;
};

export default OnBoardingGuard;
