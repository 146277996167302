// ----------------------------------------------------------------------
export const navVerticalConfig = (config) => ({
    itemGap: (config === null || config === void 0 ? void 0 : config.itemGap) || 4,
    iconSize: (config === null || config === void 0 ? void 0 : config.iconSize) || 24,
    currentRole: config === null || config === void 0 ? void 0 : config.currentRole,
    itemRootHeight: (config === null || config === void 0 ? void 0 : config.itemRootHeight) || 44,
    itemSubHeight: (config === null || config === void 0 ? void 0 : config.itemSubHeight) || 36,
    itemPadding: (config === null || config === void 0 ? void 0 : config.itemPadding) || '4px 8px 4px 12px',
    itemRadius: (config === null || config === void 0 ? void 0 : config.itemRadius) || 8,
    hiddenLabel: (config === null || config === void 0 ? void 0 : config.hiddenLabel) || false,
});
export const navMiniConfig = (config) => ({
    itemGap: (config === null || config === void 0 ? void 0 : config.itemGap) || 4,
    iconSize: (config === null || config === void 0 ? void 0 : config.iconSize) || 22,
    currentRole: config === null || config === void 0 ? void 0 : config.currentRole,
    itemRootHeight: (config === null || config === void 0 ? void 0 : config.itemRootHeight) || 56,
    itemSubHeight: (config === null || config === void 0 ? void 0 : config.itemSubHeight) || 34,
    itemPadding: (config === null || config === void 0 ? void 0 : config.itemPadding) || '6px 0 0 0',
    itemRadius: (config === null || config === void 0 ? void 0 : config.itemRadius) || 6,
    hiddenLabel: (config === null || config === void 0 ? void 0 : config.hiddenLabel) || false,
});
export const navHorizontalConfig = (config) => ({
    itemGap: (config === null || config === void 0 ? void 0 : config.itemGap) || 6,
    iconSize: (config === null || config === void 0 ? void 0 : config.iconSize) || 22,
    currentRole: config === null || config === void 0 ? void 0 : config.currentRole,
    itemRootHeight: (config === null || config === void 0 ? void 0 : config.itemRootHeight) || 32,
    itemSubHeight: (config === null || config === void 0 ? void 0 : config.itemSubHeight) || 34,
    itemPadding: (config === null || config === void 0 ? void 0 : config.itemPadding) || '0 6px 0 6px',
    itemRadius: (config === null || config === void 0 ? void 0 : config.itemRadius) || 6,
    hiddenLabel: (config === null || config === void 0 ? void 0 : config.hiddenLabel) || false,
});
