var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
// routes
import { RouterLink } from '@shopping-spree/web-utils';
//
import { Iconify } from '../../iconify';
import { StyledItem, StyledIcon } from './styles';
const NavItem = forwardRef((_a, ref) => {
    var { item, depth, open, active, externalLink, config } = _a, other = __rest(_a, ["item", "depth", "open", "active", "externalLink", "config"]);
    const theme = useTheme();
    const { title, path, icon, children, disabled, caption, roles } = item;
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledItem, Object.assign({ disableGutters: true, ref: ref, open: open, depth: depth, active: active, disabled: disabled, config: config }, other, { children: [icon && (_jsx(StyledIcon, { size: config.iconSize, sx: Object.assign({}, (subItem && { mr: 1.5 })), children: icon })), !(config.hiddenLabel && !subItem) && (_jsx(ListItemText, { sx: Object.assign({ width: 1, flex: 'unset' }, (!subItem && {
                    px: 0.5,
                    mt: 0.5,
                })), primary: title, primaryTypographyProps: Object.assign({ noWrap: true, fontSize: 10, lineHeight: '16px', textAlign: 'center', textTransform: 'capitalize', fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold' }, (subItem && {
                    textAlign: 'unset',
                    fontSize: theme.typography.body2.fontSize,
                    lineHeight: theme.typography.body2.lineHeight,
                    fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
                })) })), caption && (_jsx(Tooltip, { title: caption, arrow: true, placement: "right", children: _jsx(Iconify, { width: 16, icon: "eva:info-outline", sx: Object.assign({ color: 'text.disabled' }, (!subItem && {
                        top: 11,
                        left: 6,
                        position: 'absolute',
                    })) }) })), !!children && (_jsx(Iconify, { width: 16, icon: "eva:arrow-ios-forward-fill", sx: {
                    top: 11,
                    right: 6,
                    position: 'absolute',
                } }))] })));
    // Hidden item by role
    if (roles && !roles.includes(`${config.currentRole}`)) {
        return null;
    }
    // External link
    if (externalLink)
        return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", underline: "none", sx: Object.assign({ width: 1 }, (disabled && {
                cursor: 'default',
            })), children: renderContent }));
    // Default
    return (_jsx(Link, { component: RouterLink, href: path, underline: "none", sx: Object.assign({ width: 1 }, (disabled && {
            cursor: 'default',
        })), children: renderContent }));
});
export default NavItem;
