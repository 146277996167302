import { m } from 'framer-motion';
// @shopping-spree/web-ui
import {
  Box,
  Stack,
  Avatar,
  Divider,
  IconButton,
  Typography,
  Theme,
  alpha,
  Button,
} from '@shopping-spree/web-ui';
// components
import { varHover, CustomPopover, usePopover } from '@shopping-spree/web-ui';
import { useAppState } from 'src/context/state/use-state-context';
import { IStore } from 'src/types';
import { useRouter } from '@shopping-spree/web-utils';
import { useCallback } from 'react';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const StoreSelectionPopover = () => {
  const router = useRouter();
  const { selectedStore, setSelectedStore, organization } = useAppState();
  const popover = usePopover();

  const handleClickItem = (store: IStore) => {
    popover.onClose();
    setSelectedStore(store);
    router.replace('/');
    router.reload();
  };

  const onCreateNewStoreClick = useCallback(() => {
    popover.onClose();
    router.replace(paths.dashboard.stores.create);
  }, [popover, router]);

  const onManageStoreClick = useCallback(() => {
    popover.onClose();
    if (selectedStore) {
      router.replace(paths.dashboard.stores.edit(selectedStore.id));
    }
  }, [popover, router, selectedStore]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme: Theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={selectedStore?.images?.[0]}
          alt={selectedStore?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
      >
        <Box
          sx={{
            p: 2,
            pb: 1.5,
            display: 'flex',
            gap: '8px',
          }}
        >
          <Avatar
            src={selectedStore?.images?.[0]}
            alt={selectedStore?.name}
            sx={{
              width: 36,
              height: 36,
              border: `solid 1px grey`,
            }}
          />
          <Box>
            <Typography variant="subtitle2" sx={{ mb: -1 }}>
              {selectedStore?.address?.city}
            </Typography>
            <Typography variant="caption">
              {selectedStore?.address?.street}
            </Typography>
            <Button
              variant="outlined"
              sx={{ mt: 2, borderColor: 'black' }}
              onClick={onManageStoreClick}
            >
              Manage Store
            </Button>
          </Box>
        </Box>
        <Divider sx={{ borderStyle: 'solid', mb: '8px', mt: '4px' }} />
        <Typography variant="caption" sx={{ p: 2 }}>
          Other Stores
        </Typography>
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: '200px',
          }}
        >
          <Stack sx={{ p: 1 }}>
            {organization?.stores
              ?.map((store) => store.store)
              ?.filter((store) => store.id !== selectedStore?.id)
              ?.map((store) => (
                <Button
                  sx={{
                    justifyContent: 'flex-start',
                  }}
                  key={store.id}
                  onClick={() => handleClickItem(store)}
                >
                  <Avatar
                    src={store.images?.[0]}
                    alt={store?.name}
                    sx={{
                      width: 24,
                      height: 24,
                      mr: 1,
                    }}
                  />
                  {store.address?.city}
                </Button>
              ))}
          </Stack>
        </div>
        <Button
          variant="text"
          onClick={onCreateNewStoreClick}
          sx={{ width: '100%' }}
        >
          + Add new Store
        </Button>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </CustomPopover>
    </>
  );
};

export default StoreSelectionPopover;
