import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
//
import { fileData, fileFormat, fileThumb } from './utils';
import DownloadButton from './download-button';
export default function FileThumbnail({ file, tooltip, imageView, onDownload, sx, imgSx, }) {
    const { name = '', path = '', preview = '' } = fileData(file);
    const format = fileFormat(path || preview);
    const renderContent = format === 'image' && imageView ? (_jsx(Box, { component: "img", src: preview, sx: Object.assign({ width: 1, height: 1, flexShrink: 0, objectFit: 'cover' }, imgSx) })) : (_jsx(Box, { component: "img", src: fileThumb(format), sx: Object.assign({ width: 32, height: 32, flexShrink: 0 }, sx) }));
    if (tooltip) {
        return (_jsx(Tooltip, { title: name, children: _jsxs(Stack, { flexShrink: 0, component: "span", alignItems: "center", justifyContent: "center", sx: {
                    width: 'fit-content',
                    height: 'inherit',
                }, children: [renderContent, onDownload && _jsx(DownloadButton, { onDownload: onDownload })] }) }));
    }
    return (_jsxs(_Fragment, { children: [renderContent, onDownload && _jsx(DownloadButton, { onDownload: onDownload })] }));
}
