import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @mui
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
const StyledRoot = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'rounded',
})(({ rounded, theme }) => ({
    zIndex: 9,
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    '& li': {
        width: 18,
        height: 18,
        opacity: 0.32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&.slick-active': Object.assign({ opacity: 1 }, (rounded && {
            '& span': {
                width: 16,
                borderRadius: 6,
            },
        })),
    },
}));
const StyledDot = styled('span')(({ theme }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
    }),
}));
export default function CarouselDots(props) {
    const rounded = (props === null || props === void 0 ? void 0 : props.rounded) || false;
    const sx = props === null || props === void 0 ? void 0 : props.sx;
    return {
        appendDots: (dots) => (_jsx(_Fragment, { children: _jsx(StyledRoot, Object.assign({ component: "ul", rounded: rounded, sx: Object.assign({}, sx) }, props, { children: dots })) })),
        customPaging: () => (_jsx(Stack, { component: "div", alignItems: "center", justifyContent: "center", sx: { width: 1, height: 1 }, children: _jsx(StyledDot, { sx: {
                    bgcolor: 'currentColor',
                } }) })),
    };
}
