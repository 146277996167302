var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// utils
import '../..//utils/highlight';
import ReactMarkdown from 'react-markdown';
// markdown plugins
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
// @mui
import Link from '@mui/material/Link';
// routes
import { RouterLink } from '@shopping-spree/web-utils';
//
import { Image } from '../image';
//
import StyledMarkdown from './styles';
// ----------------------------------------------------------------------
export default function Markdown(_a) {
    var { sx } = _a, other = __rest(_a, ["sx"]);
    return (_jsx(StyledMarkdown, { sx: sx, children: _jsx(ReactMarkdown, Object.assign({ rehypePlugins: [
                rehypeRaw,
                rehypeHighlight,
                [remarkGfm, { singleTilde: false }],
            ], components: components }, other)) }));
}
// ----------------------------------------------------------------------
const components = {
    img: (_a) => {
        var props = __rest(_a, []);
        return (_jsx(Image, Object.assign({ alt: props.alt, ratio: "16/9", sx: { borderRadius: 2 } }, props)));
    },
    a: (_a) => {
        var props = __rest(_a, []);
        const isHttp = props.href.includes('http');
        return isHttp ? (_jsx(Link, Object.assign({ target: "_blank", rel: "noopener" }, props))) : (_jsx(Link, Object.assign({ component: RouterLink, href: props.href }, props, { children: props.children })));
    },
};
