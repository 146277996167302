var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogContent } from '../../common-components/dialog';
import { LoadingButton } from '../../common-components/loading-button';
import { Stack } from '../../common-components/stack';
import { useSnackbar } from '../../components/snackbar';
import { useStripe, useElements, PaymentElement, } from '@stripe/react-stripe-js';
import { useCallback, useState } from 'react';
const CheckoutFormDialog = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, []);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!stripe || !elements) {
            return;
        }
        setIsLoading(true);
        const result = yield stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000/dashboard',
            },
        });
        setIsLoading(false);
        if (result.error) {
            enqueueSnackbar({ variant: 'error', message: result.error.message });
        }
    });
    return (_jsx(Dialog, { onClose: handleCloseDialog, open: dialogOpen, maxWidth: 'sm', fullWidth: true, children: _jsx(DialogContent, { sx: { p: 4 }, children: _jsxs(Stack, { children: [_jsx(PaymentElement, {}), _jsx(LoadingButton, { type: "submit", color: "inherit", variant: "contained", sx: { mt: 4, height: '50px' }, onClick: handleSubmit, loading: isLoading, children: 'Pay' })] }) }) }));
};
export default CheckoutFormDialog;
