var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
export default function RHFSwitch(_a) {
    var { name, helperText } = _a, other = __rest(_a, ["name", "helperText"]);
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs("div", { children: [_jsx(FormControlLabel, Object.assign({ control: _jsx(Switch, Object.assign({}, field, { checked: field.value })) }, other)), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))] })) }));
}
