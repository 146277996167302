var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState, useCallback } from 'react';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import { navVerticalConfig } from '../config';
import { StyledSubheader } from './styles';
import NavList from './nav-list';
// ----------------------------------------------------------------------
function NavSectionVertical(_a) {
    var { data, config, sx } = _a, other = __rest(_a, ["data", "config", "sx"]);
    return (_jsx(Stack, Object.assign({ sx: sx }, other, { children: data.map((group, index) => (_jsx(Group, { subheader: group.subheader, items: group.items, config: navVerticalConfig(config) }, group.subheader || index))) })));
}
export default memo(NavSectionVertical);
function Group({ subheader, items, config }) {
    const [open, setOpen] = useState(true);
    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, []);
    const renderContent = items.map((list) => (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children, config: config }, list.title + list.path)));
    return (_jsx(List, { disablePadding: true, sx: { px: 2 }, children: subheader ? (_jsxs(_Fragment, { children: [_jsx(StyledSubheader, { disableGutters: true, disableSticky: true, onClick: handleToggle, config: config, children: subheader }), _jsx(Collapse, { in: open, children: renderContent })] })) : (renderContent) }));
}
