var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import LinkItem from './link-item';
// ----------------------------------------------------------------------
export default function CustomBreadcrumbs(_a) {
    var { links, action, heading, moreLink, activeLast, sx } = _a, other = __rest(_a, ["links", "action", "heading", "moreLink", "activeLast", "sx"]);
    const lastLink = links[links.length - 1].name;
    return (_jsxs(Box, { sx: Object.assign({}, sx), children: [_jsxs(Stack, { direction: "row", alignItems: "center", children: [_jsxs(Box, { sx: { flexGrow: 1 }, children: [heading && (_jsx(Typography, { variant: "h4", gutterBottom: true, children: heading })), !!links.length && (_jsx(Breadcrumbs, Object.assign({ separator: _jsx(Separator, {}) }, other, { children: links.map((link) => (_jsx(LinkItem, { link: link, activeLast: activeLast, disabled: link.name === lastLink }, link.name || ''))) })))] }), action && _jsxs(Box, { sx: { flexShrink: 0 }, children: [" ", action, " "] })] }), !!moreLink && (_jsx(Box, { sx: { mt: 2 }, children: moreLink.map((href) => (_jsx(Link, { href: href, variant: "body2", target: "_blank", rel: "noopener", sx: { display: 'table' }, children: href }, href))) }))] }));
}
// ----------------------------------------------------------------------
function Separator() {
    return (_jsx(Box, { component: "span", sx: { width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' } }));
}
