import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Grid, Typography, Button, Box, CardContent, } from '../../../index';
export const SubscriptionPlan = ({ plan, selectedPlanId, onSelect, }) => {
    var _a;
    const getGradientBackground = () => {
        switch (plan.id) {
            case '1':
                return 'linear-gradient(160deg, #FF8E53 30%, #E53935 90%)';
            case '2':
                return 'linear-gradient(160deg, #81C784 30%, #388E3C 90%)';
            case '3':
                return 'linear-gradient(160deg, #AB47BC 30%, #4A148C 90%)';
            default:
                return 'linear-gradient(160deg, #757575 30%, #424242 90%)';
        }
    };
    return (_jsx(Grid, { xs: 12, md: 4, children: _jsx(Card, { sx: {
                background: getGradientBackground(),
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                border: plan.id === selectedPlanId ? '5px solid black' : 'none',
            }, children: _jsxs(CardContent, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }, children: [_jsx(Typography, { variant: "h4", color: "white", children: plan.title }), _jsx(Box, { component: 'img', sx: { width: 48, height: 48, my: 2 }, src: 'https://cdn-icons-png.flaticon.com/512/2863/2863334.png' }), (_a = plan.prices) === null || _a === void 0 ? void 0 : _a.map((price) => (_jsx(Typography, { variant: "body2", color: 'white', children: `${price.amount / 100} ${price.currency} /${price.interval}` }, plan.remoteId))), plan.features.map((feature) => (_jsxs(Box, { children: [_jsx(Typography, { color: "white", variant: "body2", sx: { mt: 2 }, children: feature.title }), _jsx(Typography, { color: "white", variant: "caption", sx: { mt: 1 }, children: feature.description })] }, feature.id))), _jsx(Button, { variant: "contained", color: "primary", sx: { mt: 4 }, onClick: () => onSelect(plan), children: "Select" })] }) }) }, plan.id));
};
export default SubscriptionPlan;
