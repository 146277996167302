var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuItem, MenuList, Paper, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { ListItemText } from '../../common-components/table-components/list-item-text';
const SuggestionItem = ({ suggestion, getSuggestionItemProps, }) => {
    return (_jsx(MenuItem, Object.assign({}, getSuggestionItemProps(suggestion), { children: _jsx(ListItemText, { primary: suggestion.formattedSuggestion.mainText, secondary: suggestion.formattedSuggestion.secondaryText }) })));
};
export const placesToAddressMapper = (results) => __awaiter(void 0, void 0, void 0, function* () {
    const place = results === null || results === void 0 ? void 0 : results[0];
    if (!place) {
        return;
    }
    const addressComponents = place.address_components;
    const location = yield getLatLng(place);
    const streetNumberObj = addressComponents.find((comp) => comp.types.includes('street_number'));
    const streetObj = addressComponents.find((comp) => comp.types.includes('route'));
    const cityObj = addressComponents.find((comp) => comp.types.includes('locality'));
    // const areaObj = addressComponents.find((comp) =>
    //   comp.types.includes('administrative_area_level_3'),
    // );
    const postalCodeObj = addressComponents.find((comp) => comp.types.includes('postal_code'));
    const addressData = {
        address: {
            street: (streetObj === null || streetObj === void 0 ? void 0 : streetObj.long_name) || '',
            streetNumber: (streetNumberObj === null || streetNumberObj === void 0 ? void 0 : streetNumberObj.long_name) || '',
            city: (cityObj === null || cityObj === void 0 ? void 0 : cityObj.long_name) || '',
            // area: areaObj?.long_name || '',
            postalCode: (postalCodeObj === null || postalCodeObj === void 0 ? void 0 : postalCodeObj.long_name) || '',
            googlePlaceId: place.place_id,
            fullAddress: place.formatted_address,
        },
        geo: {
            lat: location.lat,
            lng: location.lng,
        },
    };
    return addressData;
});
const PlacesAddressInput = ({ onSelect, value, }) => {
    const [addressText, setAddressText] = useState(value);
    useEffect(() => {
        setAddressText(value);
    }, [value]);
    const onChange = useCallback((address) => {
        setAddressText(address);
    }, []);
    const onSelectAddress = useCallback((address) => __awaiter(void 0, void 0, void 0, function* () {
        setAddressText(address);
        try {
            const results = yield geocodeByAddress(address);
            const addressData = yield placesToAddressMapper(results);
            if (addressData) {
                onSelect(addressData);
            }
        }
        catch (error) {
            console.error('Error', error);
        }
    }), []);
    return (_jsx(_Fragment, { children: _jsx(PlacesAutocomplete, { searchOptions: {
                types: ['address'],
                componentRestrictions: { country: 'gr' },
            }, value: addressText, onChange: onChange, onSelect: onSelectAddress, children: ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (_jsxs("div", { children: [_jsx(TextField, Object.assign({ id: "outlined-basic", variant: "outlined" }, getInputProps({
                        placeholder: 'Search Places ...',
                        className: 'location-search-input',
                    }))), _jsxs("div", { className: "autocomplete-dropdown-container", children: [loading && _jsx("div", { children: "Loading..." }), _jsx(Paper, { square: true, children: suggestions.map((suggestion) => (_jsx(MenuList, { children: _jsx(SuggestionItem, { suggestion: suggestion, getSuggestionItemProps: getSuggestionItemProps }) }, suggestion.id))) })] })] })) }) }));
};
export default PlacesAddressInput;
