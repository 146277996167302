var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
// ----------------------------------------------------------------------
export default function SplashScreen(_a) {
    var { sx } = _a, other = __rest(_a, ["sx"]);
    return (_jsx(Box, Object.assign({ sx: Object.assign({ right: 0, width: 1, bottom: 0, height: 1, zIndex: 9998, display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center', bgcolor: 'background.default' }, sx) }, other, { children: _jsxs(_Fragment, { children: [_jsx(m.div, { animate: {
                        scale: [1, 0.9, 0.9, 1, 1],
                        opacity: [1, 0.48, 0.48, 1, 1],
                    }, transition: {
                        duration: 2,
                        ease: 'easeInOut',
                        repeatDelay: 1,
                        repeat: Infinity,
                    } }), _jsx(Box, { component: m.div, animate: {
                        scale: [1.6, 1, 1, 1.6, 1.6],
                        rotate: [270, 0, 0, 270, 270],
                        opacity: [0.25, 1, 1, 1, 0.25],
                        borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                    }, transition: { ease: 'linear', duration: 3.2, repeat: Infinity }, sx: {
                        width: 100,
                        height: 100,
                        position: 'absolute',
                        border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
                    } }), _jsx(Box, { component: m.div, animate: {
                        scale: [1, 1.2, 1.2, 1, 1],
                        rotate: [0, 270, 270, 0, 0],
                        opacity: [1, 0.25, 0.25, 0.25, 1],
                        borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                    }, transition: {
                        ease: 'linear',
                        duration: 3.2,
                        repeat: Infinity,
                    }, sx: {
                        width: 120,
                        height: 120,
                        position: 'absolute',
                        border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
                    } })] }) })));
}
