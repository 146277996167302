var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
//
import { Iconify } from '../iconify';
// ----------------------------------------------------------------------
const ColorPicker = forwardRef((_a, ref) => {
    var { colors, selected, onSelectColor, limit = 'auto', sx } = _a, other = __rest(_a, ["colors", "selected", "onSelectColor", "limit", "sx"]);
    const singleSelect = typeof selected === 'string';
    const handleSelect = useCallback((color) => {
        if (singleSelect) {
            if (color !== selected) {
                onSelectColor(color);
            }
        }
        else {
            const newSelected = selected.includes(color)
                ? selected.filter((value) => value !== color)
                : [...selected, color];
            onSelectColor(newSelected);
        }
    }, [onSelectColor, selected, singleSelect]);
    return (_jsx(Stack, Object.assign({ ref: ref, direction: "row", display: "inline-flex", sx: Object.assign(Object.assign({ flexWrap: 'wrap' }, (limit !== 'auto' && {
            width: limit * 36,
            justifyContent: 'flex-end',
        })), sx) }, other, { children: colors.map((color) => {
            const hasSelected = singleSelect
                ? selected === color
                : selected.includes(color);
            return (_jsx(ButtonBase, { sx: {
                    width: 36,
                    height: 36,
                    borderRadius: '50%',
                }, onClick: () => {
                    handleSelect(color);
                }, children: _jsx(Stack, { alignItems: "center", justifyContent: "center", sx: Object.assign({ width: 20, height: 20, bgcolor: color, borderRadius: '50%', border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}` }, (hasSelected && {
                        transform: 'scale(1.3)',
                        boxShadow: `4px 4px 8px 0 ${alpha(color, 0.48)}`,
                        outline: `solid 2px ${alpha(color, 0.08)}`,
                        transition: (theme) => theme.transitions.create('all', {
                            duration: theme.transitions.duration.shortest,
                        }),
                    })), children: _jsx(Iconify, { width: hasSelected ? 12 : 0, icon: "eva:checkmark-fill", sx: {
                            color: (theme) => theme.palette.getContrastText(color),
                            transition: (theme) => theme.transitions.create('all', {
                                duration: theme.transitions.duration.shortest,
                            }),
                        } }) }) }, color));
        }) })));
});
export default ColorPicker;
