import { jsx as _jsx } from "react/jsx-runtime";
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useCallback, useState } from 'react';
// hooks and utils
import { useLocalStorage, localStorageGetItem, } from '@shopping-spree/web-utils';
import { SettingsContext } from './settings-context';
export function SettingsProvider({ children, defaultSettings, }) {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [settings, setSettings] = useLocalStorage('settings', defaultSettings);
    const isArabic = localStorageGetItem('i18nextLng') === 'ar';
    useEffect(() => {
        if (isArabic) {
            onChangeDirectionByLang('ar');
        }
    }, [isArabic]);
    const onUpdate = useCallback((name, value) => {
        setSettings((prevState) => (Object.assign(Object.assign({}, prevState), { [name]: value })));
    }, [setSettings]);
    // Direction by lang
    const onChangeDirectionByLang = useCallback((lang) => {
        onUpdate('themeDirection', lang === 'ar' ? 'rtl' : 'ltr');
    }, [onUpdate]);
    // Reset
    const onReset = useCallback(() => {
        setSettings(defaultSettings);
    }, [defaultSettings, setSettings]);
    // Drawer
    const onToggleDrawer = useCallback(() => {
        setOpenDrawer((prev) => !prev);
    }, []);
    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false);
    }, []);
    const canReset = !isEqual(settings, defaultSettings);
    const memoizedValue = useMemo(() => (Object.assign(Object.assign({}, settings), { onUpdate,
        // Direction
        onChangeDirectionByLang,
        // Reset
        canReset,
        onReset, 
        // Drawer
        open: openDrawer, onToggle: onToggleDrawer, onClose: onCloseDrawer })), [
        onReset,
        onUpdate,
        settings,
        canReset,
        openDrawer,
        onCloseDrawer,
        onToggleDrawer,
        onChangeDirectionByLang,
    ]);
    return (_jsx(SettingsContext.Provider, { value: memoizedValue, children: children }));
}
