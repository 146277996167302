import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
//
import {
  AUTHENTICATE,
  GET_MY_USER,
  SIGN_UP,
} from 'src/services/Queries/authQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { useAppState } from 'src/context/state/use-state-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const { getOrganization } = useAppState();
  const [status, setStatus] = useState('loading');
  const [getMyUser] = useLazyQuery(GET_MY_USER);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loginMutation] = useMutation(AUTHENTICATE);
  const [signUpMutation] = useMutation(SIGN_UP);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await getMyUser();

        const { me: user } = response.data ?? {};

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [getMyUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const response = await loginMutation({
        variables: {
          credentials: { email, secret: password },
          provider: 'local',
        },
      });
      const { jwt, user } = response.data?.authenticate ?? {};

      if (jwt && user) {
        localStorage.setItem(STORAGE_KEY, jwt);
        dispatch({
          type: Types.LOGIN,
          payload: {
            user,
          },
        });
      }
    },
    [loginMutation],
  );

  // Login With Facebook
  const loginWithFacebook = useCallback(
    async (email: string, secret: string) => {
      const response = await signUpMutation({
        variables: {
          signUpUser: { email, secret },
          signUpProvider: 'facebook',
        },
      });
      console.log(response);
    },
    [signUpMutation],
  );

  // Login With Facebook
  const loginWithGoogle = useCallback(
    async (accessToken: string) => {
      const response = await signUpMutation({
        variables: {
          signUpUser: { email: '', secret: accessToken },
          signUpProvider: 'google',
        },
      });

      const { jwt, user } = response.data?.signUp ?? {};

      if (jwt && user) {
        localStorage.setItem(STORAGE_KEY, jwt);
        dispatch({
          type: Types.LOGIN,
          payload: {
            user,
          },
        });
      }
    },
    [signUpMutation],
  );

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
    ) => {
      const response = await signUpMutation({
        variables: {
          signUpUser: { email, secret: password },
          signUpProvider: 'local',
        },
      });

      const { jwt, user } = response?.data?.signUp ?? {};

      if (jwt && user) {
        localStorage.setItem(STORAGE_KEY, jwt);

        dispatch({
          type: Types.REGISTER,
          payload: {
            user,
          },
        });
      }
    },
    [signUpMutation],
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------
  useEffect(() => {
    const checkAuthAndOrganization = async () => {
      if (state.loading) {
        return;
      }
      if (state.user) {
        try {
          await getOrganization();
        } catch (error) {}
      }

      const checkAuthenticated = state.user
        ? 'authenticated'
        : 'unauthenticated';
      setStatus(checkAuthenticated);
    };

    checkAuthAndOrganization();
  }, [state.user, state.loading, getOrganization]);

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      loginWithFacebook,
      loginWithGoogle,
      register,
      logout,
    }),
    [
      login,
      loginWithFacebook,
      loginWithGoogle,
      logout,
      register,
      state.user,
      status,
    ],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
