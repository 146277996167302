var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, forwardRef } from 'react';
// @mui
import { Box } from '../../common-components/box';
//
import { StyledRootScrollbar, StyledScrollbar } from './styles';
// ----------------------------------------------------------------------
const Scrollbar = forwardRef((_a, ref) => {
    var { children, sx } = _a, other = __rest(_a, ["children", "sx"]);
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    if (isMobile) {
        return _jsx(Box, Object.assign({ ref: ref, sx: Object.assign({ overflow: 'auto' }, sx) }, other));
    }
    return (_jsx(StyledRootScrollbar, { children: _jsx(StyledScrollbar, Object.assign({ scrollableNodeProps: {
                ref,
            }, clickOnTrack: false, sx: sx }, other, { children: children })) }));
});
export default memo(Scrollbar);
