import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef, useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import { appBarClasses } from '@mui/material/AppBar';
// routes
import { usePathname, useActiveLink } from '@shopping-spree/web-utils';
import NavItem from './nav-item';
export default function NavList({ data, depth, hasChild, config, }) {
    var _a;
    const navRef = useRef(null);
    const pathname = usePathname();
    const active = useActiveLink(data.path, hasChild);
    const externalLink = data.path.includes('http');
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (open) {
            handleClose();
        }
    }, [pathname]);
    useEffect(() => {
        const appBarEl = Array.from(document.querySelectorAll(`.${appBarClasses.root}`));
        // Reset styles when hover
        const styles = () => {
            document.body.style.overflow = '';
            document.body.style.padding = '';
            // Apply for Window
            appBarEl.forEach((elem) => {
                elem.style.padding = '';
            });
        };
        if (open) {
            styles();
        }
        else {
            styles();
        }
    }, [open]);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { ref: navRef, item: data, depth: depth, open: open, active: active, externalLink: externalLink, onMouseEnter: handleOpen, onMouseLeave: handleClose, config: config }), hasChild && (_jsx(Popover, { open: open, anchorEl: navRef.current, anchorOrigin: { vertical: 'center', horizontal: 'right' }, transformOrigin: { vertical: 'center', horizontal: 'left' }, slotProps: {
                    paper: {
                        onMouseEnter: handleOpen,
                        onMouseLeave: handleClose,
                        sx: Object.assign({ mt: 0.5, width: 160 }, (open && {
                            pointerEvents: 'auto',
                        })),
                    },
                }, sx: {
                    pointerEvents: 'none',
                }, children: _jsx(NavSubList, { data: (_a = data.children) !== null && _a !== void 0 ? _a : [], depth: depth, config: config }) }))] }));
}
function NavSubList({ data, depth, config }) {
    return (_jsx(Stack, { spacing: 0.5, children: data.map((list) => (_jsx(NavList, { data: list, depth: depth + 1, hasChild: !!list.children, config: config }, list.title + list.path))) }));
}
