import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// routes
import { RouterLink } from '@shopping-spree/web-utils';
export default function BreadcrumbsLink({ link, activeLast, disabled }) {
    const { name, href, icon } = link;
    const styles = Object.assign({ typography: 'body2', alignItems: 'center', color: 'text.primary', display: 'inline-flex' }, (disabled &&
        !activeLast && {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'text.disabled',
    }));
    const renderContent = (_jsxs(_Fragment, { children: [icon && (_jsx(Box, { component: "span", sx: {
                    mr: 1,
                    display: 'inherit',
                    '& svg': { width: 20, height: 20 },
                }, children: icon })), name] }));
    if (href) {
        return (_jsx(Link, { component: RouterLink, href: href, sx: styles, children: renderContent }));
    }
    return _jsxs(Box, { sx: styles, children: [" ", renderContent, " "] });
}
