import { jsx as _jsx } from "react/jsx-runtime";
import SvgIcon from '@mui/material/SvgIcon';
// ----------------------------------------------------------------------
const Icon = (props) => (_jsx(SvgIcon, Object.assign({}, props, { children: _jsx("path", { d: "M12 2A10 10 0 1 1 2 12C2 6.477 6.477 2 12 2Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z" }) })));
const CheckedIcon = (props) => (_jsx(SvgIcon, Object.assign({}, props, { children: _jsx("path", { d: "M12 2A10 10 0 1 1 2 12C2 6.477 6.477 2 12 2Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17ZM12 7a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z" }) })));
export default function Radio(theme) {
    return {
        // CHECKBOX, RADIO, SWITCH
        MuiFormControlLabel: {
            styleOverrides: {
                label: Object.assign({}, theme.typography.body2),
            },
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
                icon: _jsx(Icon, {}),
                checkedIcon: _jsx(CheckedIcon, {}),
            },
            styleOverrides: {
                root: {
                    padding: theme.spacing(1),
                },
            },
        },
    };
}
