var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
// components
import { Iconify } from '../../components/iconify';
const IncrementerButton = forwardRef((_a, ref) => {
    var { quantity, onIncrease, onDecrease, disabledIncrease, disabledDecrease, sx } = _a, other = __rest(_a, ["quantity", "onIncrease", "onDecrease", "disabledIncrease", "disabledDecrease", "sx"]);
    return (_jsxs(Stack, Object.assign({ ref: ref, flexShrink: 0, direction: "row", alignItems: "center", justifyContent: "space-between", sx: Object.assign({ p: 0.5, width: 88, borderRadius: 1, typography: 'subtitle2', border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}` }, sx) }, other, { children: [_jsx(IconButton, { size: "small", onClick: onDecrease, disabled: disabledDecrease, sx: { borderRadius: 0.75 }, children: _jsx(Iconify, { icon: "eva:minus-fill", width: 16 }) }), quantity, _jsx(IconButton, { size: "small", onClick: onIncrease, disabled: disabledIncrease, sx: { borderRadius: 0.75 }, children: _jsx(Iconify, { icon: "mingcute:add-line", width: 16 }) })] })));
});
export default IncrementerButton;
