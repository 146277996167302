import { useContext } from 'react';
import { StateContext } from './state-provider';

// ----------------------------------------------------------------------

export const useAppState = () => {
  const context = useContext(StateContext);

  if (!context)
    throw new Error('useAppState context must be use inside AuthProvider');

  return context;
};
