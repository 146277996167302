var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';
//
import { UploadAvatar, Upload, UploadBox } from '../upload';
// ----------------------------------------------------------------------
export function RHFUploadAvatar(_a) {
    var { name } = _a, other = __rest(_a, ["name"]);
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs("div", { children: [_jsx(UploadAvatar, Object.assign({ error: !!error, file: field.value }, other)), !!error && (_jsx(FormHelperText, { error: true, sx: { px: 2, textAlign: 'center' }, children: error.message }))] })) }));
}
// ----------------------------------------------------------------------
export function RHFUploadBox(_a) {
    var { name } = _a, other = __rest(_a, ["name"]);
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(UploadBox, Object.assign({ files: field.value, error: !!error }, other))) }));
}
// ----------------------------------------------------------------------
export function RHFUpload(_a) {
    var { name, multiple, helperText } = _a, other = __rest(_a, ["name", "multiple", "helperText"]);
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => multiple ? (_jsx(Upload, Object.assign({ multiple: true, accept: { 'image/*': [] }, files: field.value, error: !!error, helperText: (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { px: 2 }, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText })) }, other))) : (_jsx(Upload, Object.assign({ accept: { 'image/*': [] }, file: field.value, error: !!error, helperText: (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { px: 2 }, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText })) }, other))) }));
}
