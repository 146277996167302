var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { menuItemClasses } from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
//
import { getPosition } from './utils';
import { StyledArrow } from './styles';
// ----------------------------------------------------------------------
export default function CustomPopover(_a) {
    var { open, children, arrow = 'top-right', hiddenArrow, sx } = _a, other = __rest(_a, ["open", "children", "arrow", "hiddenArrow", "sx"]);
    const { style, anchorOrigin, transformOrigin } = getPosition(arrow);
    return (_jsxs(Popover, Object.assign({ open: Boolean(open), anchorEl: open, anchorOrigin: anchorOrigin, transformOrigin: transformOrigin, slotProps: {
            paper: {
                sx: Object.assign(Object.assign(Object.assign({ width: 'auto', overflow: 'inherit' }, style), { [`& .${menuItemClasses.root}`]: {
                        '& svg': {
                            mr: 2,
                            flexShrink: 0,
                        },
                    } }), sx),
            },
        } }, other, { children: [!hiddenArrow && _jsx(StyledArrow, { arrow: arrow }), children] })));
}
