// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  ONBOARDING: '/onBoarding',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // ONBOARDING
  onBoarding: {
    root: ROOTS.ONBOARDING,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    app: `${ROOTS.DASHBOARD}/app`,
    stores: {
      root: `${ROOTS.DASHBOARD}/stores`,
      create: `${ROOTS.DASHBOARD}/stores/create`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/stores/${id}/edit`,
      details: (id: string) => `${ROOTS.DASHBOARD}/stores/${id}/details`,
    },
    members: {
      root: `${ROOTS.DASHBOARD}/members`,
      create: `${ROOTS.DASHBOARD}/members/create`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/members/${id}/edit`,
      details: (id: string) => `${ROOTS.DASHBOARD}/members/${id}/details`,
    },
    products: {
      root: `${ROOTS.DASHBOARD}/products`,
      create: `${ROOTS.DASHBOARD}/products/create`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/products/${id}/edit`,
      details: (id: string) => `${ROOTS.DASHBOARD}/products/${id}/details`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      account: `${ROOTS.DASHBOARD}/user/account`,
    },
  },
};
