var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from '@shopping-spree/web-utils';
const Logo = forwardRef((_a, ref) => {
    var { image, disabledLink = false, sx } = _a, other = __rest(_a, ["image", "disabledLink", "sx"]);
    const logo = (_jsx(Box, Object.assign({ ref: ref, component: "img", src: image, sx: Object.assign({ width: 140, height: 40, display: 'inline-flex' }, sx) }, other)));
    if (disabledLink) {
        return logo;
    }
    return (_jsx(Link, { component: RouterLink, href: "/", sx: { display: 'contents' }, children: logo }));
});
export default Logo;
