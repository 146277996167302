import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
// @mui
import Collapse from '@mui/material/Collapse';
// routes
import { usePathname, useActiveLink } from '@shopping-spree/web-utils';
import NavItem from './nav-item';
export default function NavList({ data, depth, hasChild, config, }) {
    var _a;
    const pathname = usePathname();
    const active = useActiveLink(data.path, hasChild);
    const externalLink = data.path.includes('http');
    const [open, setOpen] = useState(active);
    useEffect(() => {
        if (!active) {
            handleClose();
        }
    }, [pathname]);
    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { item: data, depth: depth, open: open, active: active, externalLink: externalLink, onClick: handleToggle, config: config }), hasChild && (_jsx(Collapse, { in: open, unmountOnExit: true, children: _jsx(NavSubList, { data: (_a = data.children) !== null && _a !== void 0 ? _a : [], depth: depth, config: config }) }))] }));
}
function NavSubList({ data, depth, config }) {
    let childrenArray = [];
    if (Array.isArray(data)) {
        childrenArray = data;
    }
    else if (data) {
        childrenArray = [data];
    }
    return (_jsx(_Fragment, { children: childrenArray.map((list) => (_jsx(NavList, { data: list, depth: depth + 1, hasChild: !!list.children, config: config }, list.title + list.path))) }));
}
