var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
export function RHFSelect(_a) {
    var { name, native, maxHeight = 220, helperText, children, PaperPropsSx } = _a, other = __rest(_a, ["name", "native", "maxHeight", "helperText", "children", "PaperPropsSx"]);
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextField, Object.assign({}, field, { select: true, fullWidth: true, SelectProps: {
                native,
                MenuProps: {
                    PaperProps: {
                        sx: Object.assign(Object.assign({}, (!native && {
                            maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                        })), PaperPropsSx),
                    },
                },
                sx: { textTransform: 'capitalize' },
            }, error: !!error, helperText: error ? error === null || error === void 0 ? void 0 : error.message : helperText }, other, { children: children }))) }));
}
export function RHFMultiSelect(_a) {
    var { name, chip, label, options, checkbox, placeholder, helperText, sx } = _a, other = __rest(_a, ["name", "chip", "label", "options", "checkbox", "placeholder", "helperText", "sx"]);
    const { control } = useFormContext();
    const renderValues = (selectedIds) => {
        const selectedItems = options.filter((item) => selectedIds.includes(item.value));
        if (!selectedItems.length && placeholder) {
            return (_jsx(Box, { component: "em", sx: { color: 'text.disabled' }, children: placeholder }));
        }
        if (chip) {
            return (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: selectedItems.map((item) => (_jsx(Chip, { size: "small", label: item.label }, item.value))) }));
        }
        return selectedItems.map((item) => item.label).join(', ');
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { sx: sx, children: [label && _jsxs(InputLabel, { id: name, children: [" ", label, " "] }), _jsxs(Select, Object.assign({}, field, { multiple: true, displayEmpty: !!placeholder, labelId: name, input: _jsx(OutlinedInput, { fullWidth: true, label: label, error: !!error }), renderValue: renderValues }, other, { children: [placeholder && (_jsx(MenuItem, { disabled: true, value: "", children: _jsxs("em", { children: [" ", placeholder, " "] }) })), options.map((option) => {
                            const selected = field.value.includes(option.value);
                            return (_jsxs(MenuItem, { value: option.value, children: [checkbox && _jsx(Checkbox, { size: "small", disableRipple: true, checked: selected }), option.label] }, option.value));
                        })] })), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))] })) }));
}
