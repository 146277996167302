import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/context/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from '@shopping-spree/web-ui';
import OnBoardingGuard from 'src/context/auth/guard/on-boarding-guard';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// STORE
const StoreList = lazy(() => import('src/pages/dashboard/stores/list'));
const StoreCreate = lazy(() => import('src/pages/dashboard/stores/create'));
const StoreEdit = lazy(() => import('src/pages/dashboard/stores/edit'));
const StoreDetails = lazy(() => import('src/pages/dashboard/stores/details'));

// MEMBERS
const MembersList = lazy(() => import('src/pages/dashboard/members/list'));
const MembersCreate = lazy(() => import('src/pages/dashboard/members/create'));
const MembersEdit = lazy(() => import('src/pages/dashboard/members/edit'));

// PRODUCTS
const ProductsList = lazy(() => import('src/pages/dashboard/products/list'));
const ProductsCreate = lazy(
  () => import('src/pages/dashboard/products/create'),
);
const ProductsEdit = lazy(() => import('src/pages/dashboard/products/edit'));
const ProductsDetails = lazy(
  () => import('src/pages/dashboard/products/details'),
);

// USER
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <OnBoardingGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </OnBoardingGuard>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'stores',
        children: [
          { element: <StoreList />, index: true },
          { path: 'create', element: <StoreCreate /> },
          { path: ':id/edit', element: <StoreEdit /> },
          { path: ':id/details', element: <StoreDetails /> },
        ],
      },
      {
        path: 'members',
        children: [
          { element: <MembersList />, index: true },
          { path: 'create', element: <MembersCreate /> },
          { path: ':id/edit', element: <MembersEdit /> },
        ],
      },
      {
        path: 'products',
        children: [
          { element: <ProductsList />, index: true },
          { path: 'create', element: <ProductsCreate /> },
          { path: ':id/edit', element: <ProductsEdit /> },
          { path: ':id/details', element: <ProductsDetails /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserAccountPage />, index: true },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
    ],
  },
];
