var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// theme
import { bgBlur } from '../../theme/css';
//
import { LeftIcon, RightIcon } from './arrow-icons';
// ----------------------------------------------------------------------
const StyledRoot = styled(Box)(({ theme }) => (Object.assign(Object.assign({}, bgBlur({
    opacity: 0.48,
    color: theme.palette.grey[900],
})), { zIndex: 9, display: 'inline-flex', alignItems: 'center', position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2), padding: theme.spacing(0.25), color: theme.palette.common.white, borderRadius: theme.shape.borderRadius })));
const StyledIconButton = styled(IconButton)({
    width: 28,
    height: 28,
    padding: 0,
    opacity: 0.48,
    '&:hover': { opacity: 1 },
});
export default function CarouselArrowIndex(_a) {
    var { index, total, onNext, onPrev, icon, sx } = _a, other = __rest(_a, ["index", "total", "onNext", "onPrev", "icon", "sx"]);
    const theme = useTheme();
    const isRTL = theme.direction === 'rtl';
    return (_jsxs(StyledRoot, Object.assign({ sx: sx }, other, { children: [_jsx(StyledIconButton, { color: "inherit", onClick: onPrev, children: _jsx(LeftIcon, { icon: icon, isRTL: isRTL }) }), _jsxs(Typography, { variant: "subtitle2", component: "span", sx: { mx: 0.25 }, children: [index + 1, "/", total] }), _jsx(StyledIconButton, { color: "inherit", onClick: onNext, children: _jsx(RightIcon, { icon: icon, isRTL: isRTL }) })] })));
}
