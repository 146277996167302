import { useState, useCallback } from 'react';
export default function useTable(props) {
    const [dense, setDense] = useState(!!(props === null || props === void 0 ? void 0 : props.defaultDense));
    const [page, setPage] = useState((props === null || props === void 0 ? void 0 : props.defaultCurrentPage) || 0);
    const [orderBy, setOrderBy] = useState((props === null || props === void 0 ? void 0 : props.defaultOrderBy) || 'name');
    const [rowsPerPage, setRowsPerPage] = useState((props === null || props === void 0 ? void 0 : props.defaultRowsPerPage) || 5);
    const [order, setOrder] = useState((props === null || props === void 0 ? void 0 : props.defaultOrder) || 'asc');
    const [selected, setSelected] = useState((props === null || props === void 0 ? void 0 : props.defaultSelected) || []);
    const onSort = useCallback((id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    }, [order, orderBy]);
    const onSelectRow = useCallback((inputValue) => {
        const newSelected = selected.includes(inputValue)
            ? selected.filter((value) => value !== inputValue)
            : [...selected, inputValue];
        setSelected(newSelected);
    }, [selected]);
    const onChangeRowsPerPage = useCallback((event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    }, []);
    const onChangeDense = useCallback((event) => {
        setDense(event.target.checked);
    }, []);
    const onSelectAllRows = useCallback((checked, inputValue) => {
        if (checked) {
            setSelected(inputValue);
            return;
        }
        setSelected([]);
    }, []);
    const onChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);
    const onResetPage = useCallback(() => {
        setPage(0);
    }, []);
    const onUpdatePageDeleteRow = useCallback((totalRowsInPage) => {
        setSelected([]);
        if (page) {
            if (totalRowsInPage < 2) {
                setPage(page - 1);
            }
        }
    }, [page]);
    const onUpdatePageDeleteRows = useCallback(({ totalRows, totalRowsInPage, totalRowsFiltered, }) => {
        const totalSelected = selected.length;
        setSelected([]);
        if (page) {
            if (totalSelected === totalRowsInPage) {
                setPage(page - 1);
            }
            else if (totalSelected === totalRowsFiltered) {
                setPage(0);
            }
            else if (totalSelected > totalRowsInPage) {
                const newPage = Math.ceil((totalRows - totalSelected) / rowsPerPage) - 1;
                setPage(newPage);
            }
        }
    }, [page, rowsPerPage, selected.length]);
    return {
        dense,
        order,
        page,
        orderBy,
        rowsPerPage,
        //
        selected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangePage,
        onChangeDense,
        onResetPage,
        onChangeRowsPerPage,
        onUpdatePageDeleteRow,
        onUpdatePageDeleteRows,
        //
        setPage,
        setDense,
        setOrder,
        setOrderBy,
        setSelected,
        setRowsPerPage,
    };
}
