var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
//
import { getRatio } from './utils';
// ----------------------------------------------------------------------
const Image = forwardRef((_a, ref) => {
    var { ratio, overlay, disabledEffect = false, 
    //
    alt, src, afterLoad, delayTime, threshold, beforeLoad, delayMethod, placeholder, wrapperProps, scrollPosition, effect = 'blur', visibleByDefault, wrapperClassName, useIntersectionObserver, sx } = _a, other = __rest(_a, ["ratio", "overlay", "disabledEffect", "alt", "src", "afterLoad", "delayTime", "threshold", "beforeLoad", "delayMethod", "placeholder", "wrapperProps", "scrollPosition", "effect", "visibleByDefault", "wrapperClassName", "useIntersectionObserver", "sx"]);
    const theme = useTheme();
    const overlayStyles = !!overlay && {
        '&:before': {
            content: "''",
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            zIndex: 1,
            position: 'absolute',
            background: overlay || alpha(theme.palette.grey[900], 0.48),
        },
    };
    const content = (_jsx(Box, { component: LazyLoadImage, 
        //
        alt: alt, src: src, afterLoad: afterLoad, delayTime: delayTime, threshold: threshold, beforeLoad: beforeLoad, delayMethod: delayMethod, placeholder: placeholder, wrapperProps: wrapperProps, scrollPosition: scrollPosition, visibleByDefault: visibleByDefault, effect: disabledEffect ? undefined : effect, useIntersectionObserver: useIntersectionObserver, wrapperClassName: wrapperClassName || 'component-image-wrapper', placeholderSrc: disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg', 
        //
        sx: Object.assign({ width: 1, height: 1, objectFit: 'cover', verticalAlign: 'bottom' }, (!!ratio && {
            top: 0,
            left: 0,
            position: 'absolute',
        })) }));
    return (_jsx(Box, Object.assign({ ref: ref, component: "span", className: "component-image", sx: Object.assign(Object.assign(Object.assign(Object.assign({ overflow: 'hidden', position: 'relative', verticalAlign: 'bottom', display: 'inline-block' }, (!!ratio && {
            width: 1,
        })), { '& span.component-image-wrapper': Object.assign({ width: 1, height: 1, verticalAlign: 'bottom', backgroundSize: 'cover !important' }, (!!ratio && {
                pt: getRatio(ratio),
            })) }), overlayStyles), sx) }, other, { children: content })));
});
export default Image;
