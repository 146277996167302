import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// utils
import { fData } from '@shopping-spree/web-utils';
//
import { Iconify } from '../iconify';
import { varFade } from '../animate';
import { fileData, FileThumbnail } from '../file-thumbnail';
// ----------------------------------------------------------------------
export default function MultiFilePreview({ thumbnail, files, onRemove, sx, }) {
    return (_jsx(AnimatePresence, { initial: false, children: files === null || files === void 0 ? void 0 : files.map((file) => {
            const { key, name = '', size = 0 } = fileData(file);
            const isNotFormatFile = typeof file === 'string';
            if (thumbnail) {
                return (_jsxs(Stack, Object.assign({ component: m.div }, varFade().inUp, { alignItems: "center", display: "inline-flex", justifyContent: "center", sx: Object.assign({ m: 0.5, width: 80, height: 80, borderRadius: 1.25, overflow: 'hidden', position: 'relative', border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}` }, sx), children: [_jsx(FileThumbnail, { tooltip: true, imageView: true, file: file, sx: { position: 'absolute' }, imgSx: { position: 'absolute' } }), onRemove && (_jsx(IconButton, { size: "small", onClick: () => onRemove(file), sx: {
                                p: 0.5,
                                top: 4,
                                right: 4,
                                position: 'absolute',
                                color: 'common.white',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                '&:hover': {
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                },
                            }, children: _jsx(Iconify, { icon: "mingcute:close-line", width: 14 }) }))] }), key));
            }
            return (_jsxs(Stack, Object.assign({ component: m.div }, varFade().inUp, { spacing: 2, direction: "row", alignItems: "center", sx: Object.assign({ my: 1, py: 1, px: 1.5, borderRadius: 1, border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}` }, sx), children: [_jsx(FileThumbnail, { file: file }), _jsx(ListItemText, { primary: isNotFormatFile ? file : name, secondary: isNotFormatFile ? '' : fData(size), secondaryTypographyProps: {
                            component: 'span',
                            typography: 'caption',
                        } }), onRemove && (_jsx(IconButton, { size: "small", onClick: () => onRemove(file), children: _jsx(Iconify, { icon: "mingcute:close-line", width: 16 }) }))] }), key));
        }) }));
}
