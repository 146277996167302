import { palette } from '../palette';
import { customShadows } from '../custom-shadows';
// ----------------------------------------------------------------------
export function contrast(contrastBold, mode) {
    const theme = Object.assign({}, (contrastBold &&
        mode === 'light' && {
        palette: {
            background: {
                default: palette(mode).grey[100],
            },
        },
    }));
    const components = Object.assign({}, (contrastBold && {
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: customShadows(mode).z4,
                },
            },
        },
    }));
    return {
        theme,
        components,
    };
}
